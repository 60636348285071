<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="require(`@/assets/images/logos/logo.png`)" max-width="200px" alt="logo" contain class="me-0">
            </v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2x4 font-weight-semibold text--primary mb-2">
            Request a new password from your manager ! 👋🏻
          </p>
          <p class="mb-2">
            Fill in your information below to have a new password
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>

          <v-form>
            <v-text-field v-model="username" outlined label="Username or Email" ref="username"
              placeholder="JohnDoe" hide-details class="mb-3">
            </v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- forgot link -->
              <a href="/login" class="mt-1"> Log in instead </a>
            </div>
            <spinner v-if="loading"></spinner>
            <v-btn v-else block color="primary" class="mt-6" @click="resetPassword()">
              Request new password
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img class="auth-mask-bg" height="173" :src="
  require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'
    }.png`)
    " />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from 'axios'
import store from "../../store";
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      isPasswordVisible: false,
      loading: false,
      username: "",
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.query.to) {
        vm.prevRoute = to.query.to
      } else {
        vm.prevRoute = "/"
      }

    })
  },

  methods:{
    async resetPassword() {
      if (this.username.length < 4) {
        store.commit("setSnackBarMessage", "User name is too short, user name needs to be at least 4 characters");
        this.$refs.username.focus()  // this causes a an auto fucos to the element
      } 
      else {
        store.commit("setSnackBarMessage", "");

        const formData = {
          "user_name_email": this.username,
        }

        this.loading = true

        await axios.post('/api/v1/topup/request/new_password', formData)
          .then(response => {
            this.$store.commit('setSnackBarMessage', response.data[0].text)
            this.$store.commit('setSnackBarColor', response.data[0].color)
            this.$store.commit('activateSnackBar', true)
            if (response.data[0].category === "success"){
              var navigation = this.$router
              setTimeout(function(){
                navigation.push('/')
              }, 3000)
            }
          })
          .catch(error => {
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          })
        this.loading = true
      }
    }
  
  },

  mounted(){
    document.title = "PettyCash: TopUp - New Password";
  },
  components: { Spinner }
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";

.is-red {
  color: red;
}

.is-green {
  color: green;
}
</style>
